import * as React from "react";

import PropTypes from "prop-types";

import { Helmet } from "react-helmet";

import { useStaticQuery, graphql } from "gatsby";

import socialBanner from "../../../static/home-screen/home_banner_2-home.png";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

function Seo({ description, lang, meta, title, keyword, pagepath }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const { domainKanha } = getMandatoryEnv(["domainKanha"]);
  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const canonicalUrl = `${domainKanha}${pagepath}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={[
        {
          rel: "canonical",
          href: canonicalUrl,
        },
      ]}
      meta={[
        {
          property: `og:image`,
          content: `${socialBanner}`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `629`,
        },
        {
          name: `og:url`,
          content: `https://heartfulness.org/kanha`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keyword || title,
        },
        {
          name: `author`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:url`,
          content: title,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `${socialBanner}`,
        },
        {
          name: `twitter:site`,
          content: ``,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  keyword: "",
  pagepath: "",
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  keyword: PropTypes.string,
  pagepath: PropTypes.string,
};

export default Seo;
